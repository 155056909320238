var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "mobile-menu",
      staticStyle: { "z-index": "99999" },
      attrs: { visible: _vm.visible, direction: "ltr", size: "90%" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c(
          "div",
          { staticClass: "logo-wrap", staticStyle: { "z-index": "99999" } },
          [
            _c(
              "div",
              { staticClass: "logo", on: { click: _vm.handleClickLogo } },
              [_vm._v(" port "), _c("span", [_vm._v("tranzit")])]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm._l(_vm.getMenuItems, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "mobile-menu__link cp",
                on: {
                  click: function ($event) {
                    return _vm.handleSettings(item.id, item.name)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            )
          }),
          _c(
            "div",
            { staticClass: "mobile-menu__link", on: { click: _vm.logout } },
            [_vm._v("Выход")]
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }