<template>
  <el-drawer
    class="mobile-menu"
    :visible.sync="visible"
    direction="ltr"
    style="z-index: 99999"
    size="90%"
  >
    <template slot="title">
      <div class="logo-wrap" style="z-index: 99999">
        <div class="logo" @click="handleClickLogo">
          port <span>tranzit</span>
        </div>
      </div>
    </template>

    <div class="body">
      <div
        v-for="item in getMenuItems"
        :key="item.id"
        class="mobile-menu__link cp"
        @click="handleSettings(item.id, item.name)"
      >
        {{ item.title }}
      </div>
      <div class="mobile-menu__link" @click="logout">Выход</div>
    </div>
  </el-drawer>
</template>

<script>
import { AUTH_LOGOUT, GET_DRAWER, SET_DRAWER } from '@/store/actions'
import { MAIN_MENU } from '@/constants/drawers'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MobileMenu',
  data() {
    return { name: MAIN_MENU, drawer: false }
  },
  computed: {
    ...mapGetters({ getDrawer: GET_DRAWER }),
    drawerData() {
      return this.getDrawer(this.name) || {}
    },
    visible: {
      get() {
        return (
          this.drawerData?.visible && this.drawerData?.data?.items.length > 0
        )
      },
      set(val) {
        this.$store.commit(SET_DRAWER, {
          name: this.name,
          visible: val,
          data: {},
        })
      },
    },
    getMenuItems() {
      return this.drawerData.data?.items
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    handleClickLogo() {
      const urlHome = 'Home'

      this.visible = false
      urlHome !== this.$route.name && this.$router.push({ name: urlHome })
    },
    handleSettings(id, route) {
      this.visible = false
      this.$router.push({ name: route })
    },
  },
}
</script>

<style lang="sass">
.mobile-menu
  .el-drawer__header
    background: $color-blue-light
    padding: 0 60px 0 $space-16
    flex-wrap: wrap
    position: relative
    margin: 0
    .logo-wrap
      flex: 0 0 100%
    .logo
      font-size: 25px
      line-height: 60px
      font-weight: 900
      text-transform: uppercase
      color: #fff
      cursor: pointer
      flex: 0 0 100%
      &>span
        color: #000
    .title
      flex: 0 0 100%

  .el-drawer__close-btn
    position: absolute
    right: 16px
    .el-dialog__close
      display: block
      width: 30px
      height: 30px
      cursor: pointer
      position: relative
      margin: 0
      &:before
        content: ''
        position: absolute
        width: 30px
        height: 30px
        background: url("~@/assets/icons/close-icon.svg") no-repeat
        background-size: contain
        left: 0
  .el-drawer__body
    padding: $space-16
  &__link
    padding: 19px 21px
    font-weight: 500
    background: #FFFFFF
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1)
    border-radius: 5px
    transition: background .3s ease
    &:hover, &:focus, &:active, &.is-active
      background: fade-out($color-blue-light, .9)

    & + &
      margin-top: 20px
</style>
