// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/close-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Colors */\n/** Colors redesign **/\n/* spaces */\n/* Buttons */\n/* Inputs */\n/* Font Size */\n/* Line height */\n/* Transition */\n/* Borders */\n/* Box shadow */\n.mobile-menu .el-drawer__header {\n  background: #4CA7D8;\n  padding: 0 60px 0 16px;\n  flex-wrap: wrap;\n  position: relative;\n  margin: 0;\n}\n.mobile-menu .el-drawer__header .logo-wrap {\n  flex: 0 0 100%;\n}\n.mobile-menu .el-drawer__header .logo {\n  font-size: 25px;\n  line-height: 60px;\n  font-weight: 900;\n  text-transform: uppercase;\n  color: #fff;\n  cursor: pointer;\n  flex: 0 0 100%;\n}\n.mobile-menu .el-drawer__header .logo > span {\n  color: #000;\n}\n.mobile-menu .el-drawer__header .title {\n  flex: 0 0 100%;\n}\n.mobile-menu .el-drawer__close-btn {\n  position: absolute;\n  right: 16px;\n}\n.mobile-menu .el-drawer__close-btn .el-dialog__close {\n  display: block;\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n  position: relative;\n  margin: 0;\n}\n.mobile-menu .el-drawer__close-btn .el-dialog__close:before {\n  content: \"\";\n  position: absolute;\n  width: 30px;\n  height: 30px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain;\n  left: 0;\n}\n.mobile-menu .el-drawer__body {\n  padding: 16px;\n}\n.mobile-menu__link {\n  padding: 19px 21px;\n  font-weight: 500;\n  background: #FFFFFF;\n  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  transition: background 0.3s ease;\n}\n.mobile-menu__link:hover, .mobile-menu__link:focus, .mobile-menu__link:active, .mobile-menu__link.is-active {\n  background: rgba(76, 167, 216, 0.1);\n}\n.mobile-menu__link + .mobile-menu__link {\n  margin-top: 20px;\n}", ""]);
// Exports
module.exports = exports;
